// Here you can add other styles


.table-text-center {
  text-align: center !important;
}

.table-text-left {
  text-align: left !important;
}

.table-text-right {
  text-align: right !important;
}

.tall-row {
  height: 65px;
  vertical-align: bottom;
}

.text-formula {
  font-size: 22px;
}

.text-explanation {
  text-align: justify;
}

.progress-row {
  margin: auto;
  padding-top: 60px;
}

.financial-progress-bar {
  padding: 0;
  margin-bottom: 60px;
}

.financial-progress-bar .progress-bar {
  background: $gradient-progress-1;
}

.financial-progress-bar-2 {
  padding: 0;
}

.financial-progress-bar-2 .progress-bar {
  background: $gradient-progress-2;
}

.financial-progress-bar-3 {
  padding: 0;
}

.financial-progress-bar-3 .progress-bar {
  background: $gradient-progress-3;
}

.ratio-index-left {
  width: 50%;
  text-align: left;
}

.ratio-index-right {
  width: 50%;
  text-align: right;
}

.ratio-index-center {
  width: 50%;
  text-align: center;
}

@keyframes fadeIn  {
  from {
    opacity: 0;
  } to {
    opacity: 1;
      }
}

.home-div {
  margin-top: 10%;
  opacity: 1;
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

.home-div img{
  width:100%
}


.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}

.semaphore-warning {
  background-color: $warning;
}

.semaphore-alert {
  background-color: $danger;
}

.semaphore-success {
  background: $success;
}

.sidebar-nav .nav-link {
  //overflow: hidden;
  //text-overflow: ellipsis !important;
  white-space: unset !important;
}

.comparative-icon {
  margin-right: 4px
}

.dropdown-item {
  cursor: pointer;
}

.tall-row-2{
  height: 45px;
  vertical-align: top;
}

.rs-nav-horizontal {
  white-space: normal !important;
}